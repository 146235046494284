<script lang="ts">
    import { QueryClient, QueryClientProvider } from "@tanstack/svelte-query";
    import { Provider as WireframeProvider } from "@evento/wireframe";
    import { Provider as EditorProvider } from "@evento/editor";
    import { Provider as DesignProvider, components, fields } from "@evento/ucme-se-prezit-components";

    const client = new QueryClient();
</script>

<template>
    <QueryClientProvider {client}>
        <WireframeProvider {components}>
            <EditorProvider {fields}>
                <DesignProvider>
                    <slot />
                </DesignProvider>
            </EditorProvider>
        </WireframeProvider>
    </QueryClientProvider>
</template>
